// @mui
import { Card, Typography } from '@mui/material'
// components
import GridCarousel from '~/components/GridCarousel'
import NextImage from '~/components/NextImage'
import { Section } from '~/components/Section'

// images
import logoTechInAsia from '~/assets/home/media/techinasia.png'
import logoIDNTimes from '~/assets/home/media/idn-times.png'
import logoCNBC from '~/assets/home/media/cnbc.png'
import logoTechnode from '~/assets/home/media/technode.png'
import logoIDXChannel from '~/assets/home/media/idx-channel.jpeg'
import logoDailySocial from '~/assets/home/media/daily-social.png'
import logoBisnisCom from '~/assets/home/media/bisnis-com.png'
import logoLiputan6 from '~/assets/home/media/liputan-6.png'
import logoMediaIndonesia from '~/assets/home/media/media-indonesia.png'
// ----------------------------------------------------------------------

const MEDIA_LOGO = Object.freeze([
  {
    name: 'TechInAsia',
    logo: logoTechInAsia
  },
  {
    name: 'IDN Times',
    logo: logoIDNTimes
  },
  {
    name: 'CNBC',
    logo: logoCNBC
  },
  {
    name: 'TechNode',
    logo: logoTechnode
  },
  {
    name: 'IDX Channel',
    logo: logoIDXChannel
  },
  {
    name: 'Daily Social',
    logo: logoDailySocial
  },
  {
    name: 'bisnis.com',
    logo: logoBisnisCom
  },
  {
    name: 'Liputan 6',
    logo: logoLiputan6
  },
  {
    name: 'Media Indonesia',
    logo: logoMediaIndonesia
  }
])

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HomeMediaCoverage() {
  return (
    <div>
      <Section>
        <Typography
          component='h2'
          variant='hx'
          marginBottom={1}
          textAlign='center'
        >
          Proudly Featured In
        </Typography>

        <GridCarousel
          columns={{ xs: 2, sm: 3, md: 5 }}
          spacing={{ xs: 1, sm: 2 }}
          peek={0.08}
        >
          {MEDIA_LOGO.map(({ logo, name }) => (
            <Card
              key={`media-${name}`}
              sx={{ padding: 1.5 }}
            >
              <NextImage
                src={logo}
                alt={name}
                nextHeight={64}
                sx={{
                  width: 'auto',
                  height: '4rem',
                  marginX: 'auto',
                  objectFit: 'contain'
                }}
              />
            </Card>
          ))}
        </GridCarousel>
      </Section>
    </div>
  )
}
