// @mui
import { Box, Card, Typography } from '@mui/material'
// components
import GridCarousel from '~/components/GridCarousel'
import NextImage from '~/components/NextImage'
import { Section } from '~/components/Section'

// images
import logoEastVentures from '~/assets/home/investors-and-affiliations/east-ventures.png'
import logoAWSEdstart from '~/assets/home/investors-and-affiliations/aws-edstart.png'
import logoLinkedinTopStartup from '~/assets/home/investors-and-affiliations/linkedin-top-startup.jpeg'
import logoKemkominfo from '~/assets/home/investors-and-affiliations/kemkominfo.png'
import logoStartupStudio from '~/assets/home/investors-and-affiliations/startup-studio.png'
import logoAlibaba from '~/assets/home/investors-and-affiliations/alibaba.png'

// ----------------------------------------------------------------------

const PARTNER_LOGO = Object.freeze([
  {
    name: 'East Ventures',
    logo: logoEastVentures
  },
  {
    name: 'AWS EdStart',
    logo: logoAWSEdstart
  },
  {
    name: 'LinkedIn Top Startup',
    logo: logoLinkedinTopStartup
  },
  {
    name: 'Kominfo',
    logo: logoKemkominfo
  },
  {
    name: 'Startup Studio',
    logo: logoStartupStudio
  },
  {
    name: 'Alibaba Asia Forward',
    logo: logoAlibaba
  }
])

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HomeInvestorsAndAffiliations() {
  return (
    <div>
      <Section>
        <Typography
          component='h2'
          variant='hx'
          marginBottom={1}
          textAlign='center'
        >
          Investors and Affiliations
        </Typography>

        <GridCarousel
          columns={{ xs: 2, sm: 3, md: 5 }}
          spacing={{ xs: 1, sm: 2 }}
          peek={0.08}
        >
          {PARTNER_LOGO.map(({ logo, name }) => (
            <Box key={`partner-${name}`}>
              <Card sx={{ padding: 1.5 }}>
                <NextImage
                  src={logo}
                  alt={name}
                  nextHeight={64}
                  sx={{
                    width: 'auto',
                    height: '4rem',
                    marginX: 'auto',
                    objectFit: 'contain'
                  }}
                />
              </Card>
            </Box>
          ))}
        </GridCarousel>
      </Section>
    </div>
  )
}
