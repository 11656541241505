// react
import { useMemo } from 'react'
// @mui
import { Box } from '@mui/material'
// components
import ClearNavbarContainer from '~/components/ClearNavbarContainer'
import GridCarousel from '~/components/GridCarousel'
import NextImage from '~/components/NextImage'
import NextLink from '~/components/NextLink'
import { Section } from '~/components/Section'
// paths
import { PATH_PAGE } from '~/routes/paths'

// page specific
import { ValueList } from './components'

// images
import BannerMain from '~/assets/banner/main.png'
import BannerElearning from '~/assets/banner/elearning.png'
import BannerBootcamp from '~/assets/banner/bootcamp.png'
// import BannerMentoring from '~/assets/banner/mentoring.png'
import BannerReviewCV from '~/assets/banner/review-cv.png'
import BannerCorporateTraining from '~/assets/banner/corporate-training.png'
import BannerExperience from '~/assets/banner/experience.png'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {()=>void} props.scrollToProductSection
 * @return {JSX.Element}
 */
export default function HomeHero({ scrollToProductSection }) {
  const carouselContent = useMemo(
    () => [
      {
        src: BannerMain,
        alt: 'MySkill',
        href: '/#product',
        onClick: scrollToProductSection
      },
      {
        src: BannerElearning,
        alt: 'MySkill E-Learning',
        href: PATH_PAGE.elearningMain,
        onClick: null
      },
      {
        src: BannerBootcamp,
        alt: 'MySkill Bootcamp',
        href: PATH_PAGE.bootcampMain,
        onClick: null
      },
      // {
      //   src: BannerMentoring,
      //   alt: 'MySkill Mentoring',
      //   href: PATH_PAGE.mentoring.reviewMain,
      //   onClick: null
      // },
      {
        src: BannerReviewCV,
        alt: 'MySkill Review CV',
        href: PATH_PAGE.mentoring.reviewMain,
        onClick: null
      },
      {
        src: BannerCorporateTraining,
        alt: 'MySkill Corporate Training',
        href: PATH_PAGE.corporateTraining,
        onClick: null
      },
      {
        src: BannerExperience,
        alt: 'MySkill Experience',
        href: PATH_PAGE.experience,
        onClick: null
      }
    ],
    [scrollToProductSection]
  )

  return (
    <div>
      <ClearNavbarContainer sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: '6rem',
            backgroundColor: 'primary.lighter',
            borderRadius: { xs: '0 0 1.5rem 1.5rem', md: '0 0 4.5rem 4.5rem' }
          }}
        />

        <Section
          sx={{
            position: 'relative',
            zIndex: 1,
            paddingTop: 0
          }}
        >
          <GridCarousel
            columns={1}
            spacing={2}
            isEnableDotNav
            peek={0.08}
          >
            {carouselContent.map(({ alt, src, href, onClick }) => (
              <Box
                component={NextLink}
                key={`home-carousel-slide-${alt}`}
                href={href}
                onClick={onClick}
                display='block'
              >
                <NextImage
                  src={src}
                  alt={alt}
                  priority
                  nextHeight={480}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    borderRadius: 2
                  }}
                />
              </Box>
            ))}
          </GridCarousel>

          <Box marginTop={2}>
            <ValueList />
          </Box>
        </Section>
      </ClearNavbarContainer>
    </div>
  )
}
