// @mui
import { Box, Card, Typography } from '@mui/material'
// components
import GridCarousel from '~/components/GridCarousel'
import NextImage from '~/components/NextImage'
// hooks
import useResponsive from '~/hooks/useResponsive'

// image
import USPExistingMember from '~/assets/home/usp/existing-member.png'
import USPAlumny from '~/assets/home/usp/alumny.png'
import USPPraktikal from '~/assets/home/usp/practical.png'
import USPRating from '~/assets/home/usp/rating.png'
import USPNewMember from '~/assets/home/usp/new-member.png'

// ----------------------------------------------------------------------

const CARDS = Object.freeze([
  {
    icon: USPExistingMember,
    primaryText: 'Lebih dari 1.5 Juta+',
    secondaryText: 'Member Belajar Bersama'
  },
  {
    icon: USPAlumny,
    primaryText: 'Ribuan Alumni Bekerja',
    secondaryText: 'di National & Global Company'
  },
  {
    icon: USPPraktikal,
    primaryText: 'Praktikal & Bersertifikat.',
    secondaryText: 'Bangun Skill dan Portfolio'
  },
  {
    icon: USPRating,
    primaryText: '4.9 Rating di Course Report',
    secondaryText: '& Award LinkedIn Top Startup'
  },
  {
    icon: USPNewMember,
    primaryText: '50k++ New Member',
    secondaryText: 'Ikut Belajar Bulan'
  }
])

// ----------------------------------------------------------------------

/**
 * @return {JSX.Element}
 */
export default function ValueList() {
  const mdUp = useResponsive('up', 'md')

  return (
    <div>
      <Typography
        component='h1'
        variant='hx'
        textAlign='center'
        marginBottom={1}
      >
        Rintis Karir Bersama MySkill
      </Typography>

      <GridCarousel
        container
        columns={{ xs: 2, sm: 3, md: 5 }}
        peek={mdUp ? 0 : 0.08}
        spacing={{ xs: 1, md: 2 }}
      >
        {CARDS.map(({ icon, primaryText, secondaryText }) => (
          <Card
            key={`landing-value-${primaryText}`}
            sx={{ height: '100%' }}
          >
            <Box
              sx={{
                padding: {
                  xs: '0.5rem !important',
                  md: '1rem !important'
                }
              }}
            >
              <Box
                paddingX='15%'
                marginBottom={1}
              >
                <NextImage
                  src={icon}
                  alt={primaryText}
                  priority
                  nextHeight={96}
                  sx={{
                    width: 'auto',
                    height: '6rem',
                    marginX: 'auto',
                    objectFit: 'contain'
                  }}
                />
              </Box>

              <Typography
                textAlign='center'
                fontSize='small'
              >
                <b>{primaryText}</b> {secondaryText}
              </Typography>
            </Box>
          </Card>
        ))}
      </GridCarousel>
    </div>
  )
}
