// react
import { useState } from 'react'
// layouts
import MainLayout from '~/layouts/main'
// components
import Page from '~/components/Page'
// sections
import {
  HomeFAQ,
  HomeHero,
  HomeMediaCoverage,
  HomeProduct,
  HomeTestimony,
  HomeInvestorsAndAffiliations,
  HomeCommunity,
  HomeCompany,
  HomeMentor
} from '~/sections/home'

// ----------------------------------------------------------------------

HomePage.getLayout = function getLayout(page) {
  return <MainLayout isClearNavbar={false}>{page}</MainLayout>
}

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HomePage() {
  const [scrollToProductSection, setScrollToProductSection] = useState(null)

  return (
    <Page
      isEnableTitlePostfix={false}
      title='MySkill | Platform Pengembangan Skill & Karir #1'
      description='Belajar & Praktek Skill Professional: Digital Marketing, Data Analysis, Ms. Excel & lainnya via eLearning, Bootcamp & Review CV. Jadilah yang paling siap!'
    >
      <HomeHero scrollToProductSection={scrollToProductSection} />
      <HomeTestimony />
      <HomeProduct setScrollToProductSection={setScrollToProductSection} />
      <HomeCommunity />
      <HomeMentor />
      <HomeCompany />
      <HomeInvestorsAndAffiliations />
      <HomeMediaCoverage />
      <HomeFAQ />
    </Page>
  )
}
