// @mui
import { Box, Card, Typography } from '@mui/material'
// components
import GridCarousel from '~/components/GridCarousel'
import NextImage from '~/components/NextImage'
import TextMaxLine from '~/components/TextMaxLine'
import { Section } from '~/components/Section'

// images
import communityMemberWorkshop from '~/assets/home/community/member-workshop.png'
import communityMeta from '~/assets/home/community/meta.png'
import communityOnePiece from '~/assets/home/community/one-piece.png'
import communityOpenAi from '~/assets/home/community/open-ai.png'
import communityTiktok from '~/assets/home/community/tiktok.png'
import communityWorkout from '~/assets/home/community/workout.png'

// ----------------------------------------------------------------------

const COMMUNITY_GALLERY = Object.freeze([
  {
    alt: 'Main & Belajar FB Ads di Kantor META Indonesia',
    image: communityMeta
  },
  {
    alt: 'Ketemu sama Sam Altman, CEO OpenAI - ChatGPT',
    image: communityOpenAi
  },
  {
    alt: 'FREE Workshop Tambahan untuk Para Member',
    image: communityMemberWorkshop
  },
  {
    alt: 'Main Games dan Bikin Video TikTok Bareng',
    image: communityTiktok
  },
  {
    alt: 'Kumpul dan Olahraga Bareng #BukanAtlet Club',
    image: communityWorkout
  },
  {
    alt: 'Nonton Film Bareng, Booking Satu Bioskop',
    image: communityOnePiece
  }
])

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HomeCommunity() {
  return (
    <div>
      <Section>
        <Typography
          component='h2'
          variant='hx'
          marginBottom={1}
          textAlign='center'
        >
          Rasanya Gabung Komunitas MySkill #SiPalingBelajar
        </Typography>

        <GridCarousel
          columns={{ xs: 1, sm: 2, md: 4 }}
          spacing={{ xs: 1, sm: 2 }}
          peek={0.08}
        >
          {COMMUNITY_GALLERY.map(({ image, alt }) => (
            <Card
              key={`community-${alt}`}
              sx={{ padding: 1 }}
            >
              <Box
                position='relative'
                width='100%'
                paddingTop='56.25%'
              >
                <NextImage
                  src={image}
                  alt={alt}
                  nextHeight={160}
                  sx={{
                    position: 'absolute',
                    inset: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: 1,
                    objectFit: 'cover'
                  }}
                />
              </Box>

              <TextMaxLine
                line={2}
                mt={1}
                fontSize='small'
                fontWeight='medium'
                textAlign='center'
                lineHeight={1.25}
                minHeight='2.5em'
              >
                {alt}
              </TextMaxLine>
            </Card>
          ))}
        </GridCarousel>
      </Section>
    </div>
  )
}
