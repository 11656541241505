// @mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
// components
import { Section } from '~/components/Section'

// ----------------------------------------------------------------------

const FAQ = Object.freeze([
  {
    question: 'Apa itu MySkill?',
    answer:
      'MySkill adalah platform persiapan karir dan peningkatan skill dengan lebih dari 1 juta pengguna. MySkill didukung oleh leading venture capital di Asia Tenggara, East Ventures.'
  },
  {
    question: 'Apa Saja Fitur di MySkill?',
    answer:
      'MySkill memiliki tiga fitur utama. eLearning untuk belajar mandiri via video, modul belajar dan webinar series bulanan. Bootcamp untuk belajar intensif fokus pada praktik via zoom bareng experts. Mentoring untuk dapat template dan review CV hingga persiapan wawancara bersama HRD.'
  },
  {
    question: 'Apakah MySkill Berbayar?',
    answer:
      'Ketiga fitur utama MySkill (eLearning, Bootcamp dan Mentoring) memiliki biaya yang affordable. Selain itu, MySkill juga memiliki program gratis seperti Short Class di halaman Bootcamp yang bisa kamu ikuti gratis.'
  },
  {
    question: 'Platform pembayaran apa saja yang digunakan pada MySkill?',
    answer:
      'Pembayaran bisa menggunakan berbagai e-wallet, QRIS, transfer bank hingga melalui swalayan terdekat.'
  },
  {
    question:
      'Kemana saya bisa mendapatkan informasi untuk bekerjasama atau jika terdapat kendala?',
    answer:
      'Silahkan, Kamu bisa menghubungi MySkill melalui WhatsApp 081212891393 atau kamu bisa klik WhatsApp button di halaman ini (terletak di kanan-bawah).'
  }
])

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HomeFAQ() {
  return (
    <div>
      <Section>
        <Typography
          component='h2'
          variant='hx'
          marginBottom={1}
          textAlign='center'
        >
          Yang Sering Ditanyakan
        </Typography>

        <Stack spacing={1}>
          {FAQ.map(({ question, answer }) => (
            <Accordion
              key={`faq-${question}`}
              sx={{ border: '1px solid', borderColor: 'divider' }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreOutlined sx={{ width: 20, height: 20 }} />
                }
              >
                <Typography fontWeight='bold'>{question}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography>{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </Section>
    </div>
  )
}
