// @mui
import { Box, Card, Typography } from '@mui/material'
// components
import NextImage from '~/components/NextImage'
import GridCarousel from '~/components/GridCarousel'
import { Section } from '~/components/Section'

// images
import logoAstra from '~/assets/home/company/astra.png'
import logoDentsu from '~/assets/home/company/dentsu.png'
import logoEfishery from '~/assets/home/company/efishery.png'
import logoGojek from '~/assets/home/company/gojek.png'
import logoHalodoc from '~/assets/home/company/halodoc.png'
import logoIdnTimes from '~/assets/home/company/idn-times.png'
import logoKitabisa from '~/assets/home/company/kitabisa.png'
import logoShopee from '~/assets/home/company/shopee.png'
import logoLionParcel from '~/assets/home/company/lion-parcel.png'
import logoDelloite from '~/assets/home/company/delloite.png'

// ----------------------------------------------------------------------

const COMPANY_LOGO = Object.freeze([
  {
    name: 'Gojek',
    logo: logoGojek
  },
  {
    name: 'Shopee',
    logo: logoShopee
  },
  {
    name: 'Astra International',
    logo: logoAstra
  },
  {
    name: 'eFishery',
    logo: logoEfishery
  },
  {
    name: 'Kitabisa.com',
    logo: logoKitabisa
  },
  {
    name: 'Halodoc',
    logo: logoHalodoc
  },
  {
    name: 'IDN Times',
    logo: logoIdnTimes
  },
  {
    name: 'Dentsu',
    logo: logoDentsu
  },
  {
    name: 'Lion Parcel',
    logo: logoLionParcel
  },
  {
    name: 'Delloite',
    logo: logoDelloite
  }
])

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HomeCompany() {
  return (
    <div>
      <Section>
        <Typography
          component='h2'
          variant='hx'
          marginBottom={2}
          textAlign='center'
        >
          Bersama Experts dan Case Study dari Notable Companies
        </Typography>

        <GridCarousel
          columns={{ xs: 2, sm: 3, md: 6 }}
          spacing={{ xs: 1, sm: 2 }}
          peek={0.08}
        >
          {COMPANY_LOGO.map(({ logo, name }) => (
            <Box key={`partner-${name}`}>
              <Card sx={{ paddingX: 1.5, paddingY: 2 }}>
                <NextImage
                  src={logo}
                  alt={name}
                  nextHeight={24}
                  sx={{
                    width: 'auto',
                    height: '1.5rem',
                    marginX: 'auto',
                    objectFit: 'contain'
                  }}
                />
              </Card>
            </Box>
          ))}
        </GridCarousel>
      </Section>
    </div>
  )
}
