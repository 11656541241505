// react
import { useEffect, useState } from 'react'
// @mui
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
// components
import NextImage from '~/components/NextImage'
import NextLink from '~/components/NextLink'
import { Section } from '~/components/Section'
// paths
import { PATH_PAGE } from '~/routes/paths'

// images
import productElearning from '~/assets/home/product/e-learning.png'
import productBootcamp from '~/assets/home/product/bootcamp.png'
import productMentoring from '~/assets/home/product/mentoring.png'
import productCorporateService from '~/assets/home/product/corporate-service.jpg'

// ----------------------------------------------------------------------

const PRODUCT_LIST = Object.freeze(
  [
    {
      name: 'E-learning',
      description:
        'Pelajari Ratusan Skill Sekali Bayar. Praktik dan Bersertifikat',
      image: productElearning,
      value: [
        'Belajar fleksibel via Video Materi, Bahan Bacaan, Project dan Studi Kasus',
        'Praktikal & Actionable. Bertahap dari level Dasar hingga Lanjut',
        'Grup Komunitas Diskusi Lifetime. Kelas Gratis Tiap Bulannya'
      ],
      buttonList: [
        { text: 'Lihat Ratusan Materi', href: PATH_PAGE.elearningMain }
      ]
    },
    {
      name: 'Bootcamp',
      description: 'Intensive Live Class bersama Experts. Praktikal & Mendalam',
      image: productBootcamp,
      value: [
        'Kombinasi Case Study, Diskusi dan Praktik di Tiap Sesi. Basic to Advanced',
        'Group Mentoring Semi-Privat untuk Bangun Portfolio',
        'Tutor Terkurasi. Memiliki Lebih dari 30.000 Alumni'
      ],
      buttonList: [
        { text: 'Lihat Ragam Bootcamp', href: PATH_PAGE.bootcampMain }
      ]
    },
    {
      name: 'Review CV',
      description: 'Dapatkan review dan dokumen persiapan karir dari HRD',
      image: productMentoring,
      value: [
        'Dapatkan 20+ Template CV (Indonesia & English), surat lamaran dan masih banyak lainnya',
        'Dokumen Ratusan QnA Wawancara dan optimalisasi Linkedin',
        'Peluang diterima magang dan kerja full time meningkat dengan bantuan review CV oleh HRD'
      ],
      buttonList: [{ text: 'Review CV', href: PATH_PAGE.mentoring.reviewMain }]
    },
    {
      name: 'Corporate Service',
      description:
        'Corporate Training dan Software HRIS Performance Management',
      image: productCorporateService,
      value: [
        'Selenggarakan Corporate Training sesuai Kebutuhan. Online & Offline',
        'Miliki Software untuk Monitor KPI Performance dan Upskilling Karyawan',
        'Dipercaya Ratusan Perusahaan: Microsoft, Telkom, Mandiri, Paragon'
      ],
      buttonList: [
        { text: 'Corporate Service', href: PATH_PAGE.corporateService },
        { text: 'Software HRIS', href: PATH_PAGE.experience }
      ]
    }
  ].map((p, i) => ({ ...p, imagePosition: i % 2 === 0 ? 'left' : 'right' }))
)

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {(func: () => void)=>void} props.setScrollToProductSection
 * @returns {JSX.Element}
 */
export default function HomeProduct({ setScrollToProductSection }) {
  const [ref, setRef] = useState(null)

  useEffect(() => {
    if (!ref) {
      setScrollToProductSection(null)
    }

    setScrollToProductSection(() => (e) => {
      e.preventDefault()

      window.scroll({ behavior: 'smooth', top: ref.offsetTop - 96 })
    })
  }, [ref, setScrollToProductSection])

  return (
    <div
      ref={(r) => setRef(r)}
      id='product'
    >
      <Section>
        <Typography
          component='h2'
          variant='hx'
          textAlign='center'
          marginBottom={4}
        >
          Berbagai Program MySkill
        </Typography>

        <Stack spacing={8}>
          {PRODUCT_LIST.map((p) => (
            <div key={p.name}>
              <Grid
                container
                spacing={{ xs: 2, md: 4 }}
                alignItems='center'
                justifyContent='center'
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  order={{ xs: 0, md: p.imagePosition === 'right' ? 1 : 0 }}
                >
                  <Box
                    textAlign={{
                      xs: 'center',
                      md: p.imagePosition === 'right' ? 'left' : 'right'
                    }}
                  >
                    <NextImage
                      src={p.image}
                      alt={p.name}
                      nextHeight={240}
                      sx={{
                        display: 'inline-block',
                        height: { xs: 'auto', md: '20ch' },
                        width: { xs: '50vw', md: 'auto' }
                      }}
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  order={{ xs: 1, md: p.imagePosition === 'right' ? 0 : 1 }}
                >
                  <Typography
                    component='h3'
                    variant='hx'
                    marginBottom={0}
                  >
                    {p.name}
                  </Typography>
                  <Typography>{p.description}</Typography>

                  <Stack
                    component='ul'
                    spacing={0.25}
                    marginTop={1}
                    sx={{ listStyleType: 'none' }}
                  >
                    {p.value.map((v) => (
                      <Typography
                        key={v}
                        component='li'
                        fontSize='0.9rem'
                        color='text.secondary'
                      >
                        ✅ {v}
                      </Typography>
                    ))}
                  </Stack>

                  <Box marginTop={1.5}>
                    <Grid
                      container
                      spacing={1.5}
                    >
                      {p.buttonList.map((btn) => (
                        <Grid
                          key={btn.text}
                          item
                          xs='auto'
                        >
                          <Button
                            LinkComponent={NextLink}
                            variant='contained'
                            size='small'
                            href={btn.href}
                          >
                            {btn.text}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </div>
          ))}
        </Stack>
      </Section>
    </div>
  )
}
