import mentor1 from '~/assets/e-learning/mentor/1.png'
import mentor2 from '~/assets/e-learning/mentor/2.png'
import mentor3 from '~/assets/e-learning/mentor/3.png'
import mentor4 from '~/assets/e-learning/mentor/4.png'
import mentor5 from '~/assets/e-learning/mentor/5.png'
import mentor6 from '~/assets/e-learning/mentor/6.png'
import mentor7 from '~/assets/e-learning/mentor/7.png'
import mentor8 from '~/assets/e-learning/mentor/8.png'
import mentor9 from '~/assets/e-learning/mentor/9.png'
import mentor10 from '~/assets/e-learning/mentor/10.png'
import mentor11 from '~/assets/e-learning/mentor/11.png'
import mentor12 from '~/assets/e-learning/mentor/12.png'
import mentor13 from '~/assets/e-learning/mentor/13.png'
import mentor14 from '~/assets/e-learning/mentor/14.png'
import mentor15 from '~/assets/e-learning/mentor/15.png'

/**
 * @typedef FeaturedMentor
 * @property {string} name
 * @property {string|import('next/image').StaticImageData} image
 * @property {string} contentPath
 *
 * @type {FeaturedMentor[]}
 */
export const FEATURED_MENTOR = Object.freeze([
  {
    name: 'Rizki Teguh Kurniawan',
    title: 'Data Scientist, E-Fishery',
    image: mentor1,
    contentPath: '/topic/data-analysis'
  },
  {
    name: 'Putri Nindya',
    title: 'Social Media Manager, Halodoc',
    image: mentor2,
    contentPath: '/topic/social-media-marketing'
  },
  {
    name: 'Gabrielle Ratri',
    title: 'UX Writer, DANA',
    image: mentor3,
    contentPath: '/topic/user-experience-writing'
  },
  {
    name: 'M. Fadel Noorsal ',
    title: 'Product Growth Lead, Gojek',
    image: mentor4,
    contentPath: '/topic/product-growth'
  },
  {
    name: 'Abdullah Zaky Syahab',
    title: 'Frontend Developer Lead, Mister Aladin',
    image: mentor5,
    contentPath: '/topic/frontend-javascript'
  },
  {
    name: 'Gilang Perdana',
    title: 'Sr. Frontend Developer, Smartfren',
    image: mentor6,
    contentPath: '/topic/frontend-css'
  },
  {
    name: 'Alvin Trianto',
    title: 'QA Engineer, Evermos',
    image: mentor7,
    contentPath: '/learning-path/software-quality-assurance'
  },
  {
    name: 'May Williana',
    title: "Master Student, King's College University",
    image: mentor8,
    contentPath: '/topic/toefl-itp'
  },
  {
    name: 'Pramono Hadi Sigit',
    title: 'Senior Graphic Designer, Bukalapak',
    image: mentor9,
    contentPath: '/topic/basic-data'
  },
  {
    name: 'Rahma Yustika Dewi',
    title: 'Head of People Operations, PinHome',
    image: mentor10,
    contentPath: '/topic/employee-performance-development'
  },
  {
    name: 'Ari Sulistiyo Prabowo',
    title: 'Data Analyst Lead, ULA',
    image: mentor11,
    contentPath: '/topic/people-analytics'
  },
  {
    name: 'Fabiola Meseaga',
    title: 'Senior People Strategy and Analytics, Mekari',
    image: mentor12,
    contentPath: '/topic/people-analytics'
  },
  {
    name: 'Maretta Paulakarin',
    title: 'Area Sales Promotion Manager, Indofood CBP',
    image: mentor13,
    contentPath: '/topic/business-to-consumer-sales-b2c'
  },
  {
    name: 'Nurul Islamia ',
    title: 'Business Development Lead, Mamikos.com',
    image: mentor14,
    contentPath: '/topic/business-development'
  },
  {
    name: 'M. Rizky Eka Arlin',
    title: 'Sr. Tech Lead, Octopus Indonesia',
    image: mentor15,
    contentPath: '/topic/artificial-intelligence'
  }
])
