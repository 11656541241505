// @mui
import { Typography } from '@mui/material'
// components
import MemberTestimony from '~/components/MemberTestimony'
import { Section } from '~/components/Section'
// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HomeTestimony() {
  return (
    <div>
      <Section>
        <Typography
          component='h2'
          variant='hx'
          textAlign='center'
          marginBottom={1}
        >
          Terbukti Berdampak dan Membuka Batasan
        </Typography>

        <MemberTestimony />
      </Section>
    </div>
  )
}
