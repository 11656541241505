// @mui
import { Box, Button, Card, Typography } from '@mui/material'
// components
import GridCarousel from '~/components/GridCarousel'
import NextImage from '~/components/NextImage'
import TextMaxLine from '~/components/TextMaxLine'
import NextLink from '~/components/NextLink'
import { Section } from '~/components/Section'
// paths
import { FEATURED_MENTOR } from '~/constants/mentor'

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HomeMentor() {
  return (
    <div>
      <Section>
        <Typography
          component='h2'
          variant='hx'
          marginBottom={1}
          textAlign='center'
        >
          Belajar dari Para Senior Operator. Walk the Talk
        </Typography>

        <GridCarousel
          columns={{ xs: 2, sm: 3, lg: 5 }}
          spacing={{ xs: 1, sm: 2 }}
          peek={0.08}
        >
          {FEATURED_MENTOR.map(({ image, name, title, contentPath }) => (
            <Card
              key={`mentor-${name}`}
              sx={{ padding: 1 }}
            >
              <Box
                position='relative'
                width='100%'
                paddingTop='110%'
                mb={1}
              >
                <NextImage
                  src={image}
                  alt={name}
                  nextHeight={180}
                  sx={{
                    position: 'absolute',
                    inset: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: 1,
                    objectFit: 'cover',
                    objectPosition: 'top'
                  }}
                />
              </Box>

              <TextMaxLine
                line={1}
                fontWeight='bold'
              >
                {name}
              </TextMaxLine>

              <TextMaxLine
                line={2}
                lineHeight={1.25}
                fontSize='small'
                minHeight='2.5em'
                mb={1}
              >
                {title}
              </TextMaxLine>

              <Button
                LinkComponent={NextLink}
                href={contentPath}
                variant='contained'
                color='primary'
                size='small'
                fullWidth
              >
                Lihat Materi
              </Button>
            </Card>
          ))}
        </GridCarousel>
      </Section>
    </div>
  )
}
